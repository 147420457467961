import Carousel from 'react-slick';
import styled from 'styled-components';

import { APP_IMG, SHOW_COMMEMORATE } from 'src/constants';

const SectionCover = ({ data }) => {
  const urlImage = `${APP_IMG}/uploads/images/event/`;

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <>
      <SectionCoverDesktop className='show-desktop' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        {
          <Carousel {...settings}>
            {data
              .filter(data => data.imagePath !== null)
              .map((item, index) => (
                <div key={index} className='banner-special'>
                  <a
                    href={!item.url ? '#' : item.url}
                    tabIndex='-1'
                    //! DATALAYER

                    title={item.title}
                    target={!item.url ? '_self' : '_blank'}
                    rel={!item?.title ? '' : 'noopener noreferrer nofollow'}
                  >
                    <img src={`${urlImage}${item.imagePath}`} alt={item.title} title={item.title} width='100%' height='100%' />
                  </a>
                </div>
              ))}
          </Carousel>
        }
      </SectionCoverDesktop>
      <SectionCoverMobile className='show-mobile' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        {
          <Carousel {...settings}>
            {data
              .filter(data => data.imagePathMobile !== null)
              .map((item, index) => (
                <div key={index} className='banner-special'>
                  <a
                    href={!item.url ? '#' : item.url}
                    tabIndex='-1'
                    //! DATALAYER

                    title={item.title}
                    target={!item.url ? '_self' : '_blank'}
                    rel={!item?.title ? '' : 'noopener noreferrer nofollow'}
                  >
                    <img src={`${urlImage}${item.imagePathMobile}`} alt={item.title} title={item.title} width='100%' height='100%' />
                  </a>
                </div>
              ))}
          </Carousel>
        }
      </SectionCoverMobile>
    </>
  );
};

const SectionCoverDesktop = styled.div`
  display: block;
  overflow-x: hidden;
  @media (max-width: 767px) {
    display: none;
  }
`;
const SectionCoverMobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    overflow-x: hidden;
  }
`;
export default SectionCover;
